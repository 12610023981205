import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { captureException } from "util/exception";
import AlertMessage from "common/core/alert_message";
import FrontIdExample from "assets/images/capture_portal/front-id-example.svg";
import BackIdExample from "assets/images/capture_portal/back-id-example.svg";
import Check from "assets/images/check.svg";

import Button from "../common/button";

class ConfirmPhoto extends Component {
  state = {
    isUploading: false,
    hasError: false,
  };

  onConfirm = () => {
    const { onConfirm, isBack } = this.props;

    this.setState({ isUploading: true, hasError: false }, () => {
      onConfirm().catch((err) => {
        captureException(err, { isBack });
        this.setState({ isUploading: false, hasError: true });
      });
    });
  };

  render() {
    const { isUploading, hasError } = this.state;
    const { onRetake, screenshot, isBack } = this.props;
    const idExample = isBack ? BackIdExample : FrontIdExample;

    return (
      <div className="CaptureApp--confirmPhoto">
        {hasError && (
          <AlertMessage className="CaptureApp--confirmPhoto--alert">
            Oops! There was an issue uploading your photo.
          </AlertMessage>
        )}
        <div className="CaptureApp--confirmPhoto--help">
          <div className="CaptureApp--confirmPhoto--help--example">
            Example
            <img
              className="CaptureApp--confirmPhoto--help--image"
              alt="id-example"
              src={idExample}
            />
          </div>
          <div className="CaptureApp--confirmPhoto--help--guide">
            <div className="CaptureApp--confirmPhoto--help--guide--text">
              <img
                className="CaptureApp--confirmPhoto--help--guide--check"
                alt="checkmark"
                src={Check}
              />
              Name is legible
            </div>
            <div className="CaptureApp--confirmPhoto--help--guide--text">
              <img
                className="CaptureApp--confirmPhoto--help--guide--check"
                alt="checkmark"
                src={Check}
              />
              Full ID is visible
            </div>
          </div>
        </div>
        <div className="CaptureApp--confirmPhoto--imageText">Do you want to use this image?</div>
        <img src={screenshot} alt="screenshot" className="CaptureApp--confirmPhoto--screenshot" />
        <div className="CaptureApp--confirmPhoto--buttonGroup">
          <Button
            large
            action
            fullwidth
            onClick={this.onConfirm}
            disabled={isUploading}
            isLoading={isUploading}
            className="CaptureApp--confirmPhoto--button"
            automationId="confirm-photo"
          >
            <FormattedMessage
              id="0495bd16-20e8-413b-a9de-0b45ec72af45"
              defaultMessage="Confirm photo"
            />
          </Button>
          <Button
            large
            action
            fullwidth
            hollow
            onClick={onRetake}
            disabled={isUploading}
            className="CaptureApp--confirmPhoto--button"
            automationId="retake-photo"
          >
            <FormattedMessage
              id="c91bd3ae-517d-42b3-b5dd-738501a4ac5d"
              defaultMessage="Retake photo"
            />
          </Button>
        </div>
      </div>
    );
  }
}

ConfirmPhoto.propTypes = {
  onRetake: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  screenshot: PropTypes.string.isRequired,
  isBack: PropTypes.bool,
};

export default ConfirmPhoto;
