import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";

import PictureSubmitted from "assets/images/picture_submitted.svg";

const noop = () => {};

class Submit extends Component {
  constructor(props) {
    super(props);

    this.pin = null;
    this.state = { fullName: null };
  }

  componentDidMount() {
    const { signerIdentityService, captureAnalytics } = this.props;

    captureAnalytics.trackSignerSubmitIdSuccessful({
      signerIdentityId: signerIdentityService.getId(),
    });
    signerIdentityService
      .getFullName()
      .then((fullName) => {
        this.setState({ fullName });
      })
      .catch(noop);
  }

  render() {
    const { fullName } = this.state;

    return (
      <div className="SubmitBody">
        <img className="SubmitBody--Picture" src={PictureSubmitted} alt="Pictures submitted" />
        <span
          className="SubmitBody--Text SubmitBody--Text--Name"
          data-automation-id="signer-identity-full-name"
        >
          You're all set{fullName && `, ${fullName}`}!
        </span>
        <span className="SubmitBody--Text SubmitBody--Text--Instructions">
          Please return to your computer to complete the meeting.
        </span>
      </div>
    );
  }
}

Submit.propTypes = {
  captureAnalytics: PropTypes.shape({
    trackSignerSubmitIdSuccessful: PropTypes.func.isRequired,
  }).isRequired,
  signerIdentityService: PropTypes.shape({
    getFullName: PropTypes.func.isRequired,
  }).isRequired,
};

Submit.defaultProps = {
  captureAnalytics: {
    trackSignerSubmitIdSuccessful: noop,
  },
  signerIdentityService: {
    getId: () => undefined,
    getFullName: () => Promise.reject(),
  },
};

export default Submit;
