import { Routes, Route } from "react-router-dom";

import CaptureApp from "capture_portal";
import SelectType from "capture_portal/select_id";
import FrontCapture from "capture_portal/front_capture";
import BackCapture from "capture_portal/back_capture";
import SecondaryCapture from "capture_portal/secondary_capture";
import Submit from "capture_portal/submit";

const APP = (
  <CaptureApp>
    {(props: Record<string, unknown>) => (
      <Routes>
        <Route path="/selectId" element={<SelectType {...props} />} />
        <Route path="/selectId/:si" element={<SelectType {...props} />} />
        <Route path="/front/:si" element={<FrontCapture {...props} />} />
        <Route path="/back/:si" element={<BackCapture {...props} />} />
        <Route path="/secondary/:si" element={<SecondaryCapture {...props} />} />
        <Route path="/submit" element={<Submit {...props} />} />
      </Routes>
    )}
  </CaptureApp>
);

export default () => APP;
