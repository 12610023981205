import "./index.scss";

import PropTypes from "prop-types";
import classnames from "classnames";

import { captureBreadcrumb } from "util/exception";

export default function Button({
  children,
  className,
  onClick,
  disabled,
  isLoading,
  type,
  small,
  medium,
  large,
  fullwidth,
  hollow,
  action,
  danger,
  white,
  default: defaultColor,
  automationId,
}) {
  const Children = <div className="CoreButton-children">{children}</div>;

  const cx = classnames("CoreButton", "Button", className, {
    "Button-default": defaultColor === true,
    "is-loading": isLoading === true,
    "is-small": small === true,
    "is-medium": medium === true,
    "is-large": large === true,
    "is-fullwidth": fullwidth === true,
    "is-hollow": hollow === true,
    "Button-action": action === true,
    "Button-danger": danger === true,
    "Button-white": white === true,
  });

  function validateOnClick() {
    // onClick may be undefined if we're using the button as a form submit
    if (!disabled && !isLoading && onClick) {
      captureBreadcrumb({
        message: "Core Button Clicked",
        data: {
          className: cx,
          dataAutomationId: automationId,
        },
        category: "ui.click",
      });
      onClick();
    }
  }

  return (
    <button
      className={cx}
      disabled={disabled || isLoading}
      onClick={validateOnClick}
      type={type} // eslint-disable-line react/button-has-type
      data-automation-id={automationId}
    >
      {Children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  fullwidth: PropTypes.bool,
  hollow: PropTypes.bool,
  action: PropTypes.bool,
  danger: PropTypes.bool,
  white: PropTypes.bool,
  default: PropTypes.bool,
  automationId: PropTypes.string,
};

Button.defaultProps = {
  disabled: false,
  isLoading: false,
  type: "button",
  automationId: "button",
};
