import "./index.scss";

import PropTypes from "prop-types";

import StepIndicator from "../common/step_indicator";
import { STEPS, PATHNAME_TO_STEP, PRIMARY_ID, SECONDARY_ID } from "./constants";

const SECONDARY_PATHNAMES = {
  ...PATHNAME_TO_STEP,
  front: PRIMARY_ID,
  back: PRIMARY_ID,
  secondary: SECONDARY_ID,
};
const SECONDARY_STEPS = STEPS.slice();
SECONDARY_STEPS.splice(1, 2, { name: PRIMARY_ID }, { name: SECONDARY_ID });

function Steps({ location, secondaryIdRequired }) {
  const [, currentPathname] = location.pathname.split("/"); // looks like ["", "front", "SIGNER_IDENTITY_ID"]
  const steps = secondaryIdRequired ? SECONDARY_STEPS : STEPS;
  const stepName = secondaryIdRequired
    ? SECONDARY_PATHNAMES[currentPathname]
    : PATHNAME_TO_STEP[currentPathname];
  return (
    <div className="CaptureAppSteps">
      <StepIndicator steps={steps} stepName={stepName} useNameAsLabel />
    </div>
  );
}

Steps.propTypes = {
  secondaryIdRequired: PropTypes.bool.isRequired,
};

export default Steps;
