import { getBlobFromDataURI } from "util/uploader";
import { CAPTURE_ID_TYPE } from "constants/id_validation";

import { get, post, put, putFile } from "../http";

export default class SignerIdentityService {
  constructor(signerIdentityId) {
    this.signerIdentityId = signerIdentityId;
    this.signerIdentityInfo = null;
    this.photoIdentification = {
      id: null,
      frontUrl: null,
      backUrl: null,
    };
    this.pin = null;
    this.frontPicture = null;
    this.backPicture = null;
    this.documentClaimedType = null;

    // We set these to resolve in the beginning because not every ID type has both front/back uploads
    // They get changed to rejects if something goes wrong after calling uploadFrontPicture or uploadBackPicture
    this.frontUploadPromise = Promise.resolve();
    this.backUploadPromise = Promise.resolve();
  }

  clearFrontPicture() {
    this.frontPicture = null;
    return this;
  }

  clearBackPicture() {
    this.backPicture = null;
    return this;
  }

  createPhotoIdentification = (identificationType = CAPTURE_ID_TYPE.PRIMARY) => {
    return post(`/signer_identities/${this.signerIdentityId}/photo_identifications`, {
      pin: this.pin,
      photo_identification_type: identificationType,
    }).then((data) => {
      this.photoIdentification.id = data.photo_identification_id;
      this.photoIdentification.frontUrl = data.front_url;
      this.photoIdentification.backUrl = data.back_url;
    });
  };

  getBackUrl = () => {
    return this.photoIdentification.backUrl;
  };

  getFrontUrl = () => {
    return this.photoIdentification.frontUrl;
  };

  getFullName = () => {
    if (this.signerIdentityInfo) {
      return Promise.resolve(this.signerIdentityInfo.fullName);
    }
    return this.getInfo()
      .then((signerIdentityInfo) => signerIdentityInfo.fullName)
      .catch(() => null);
  };

  getDocumentClaimedType = () => {
    return this.documentClaimedType;
  };

  getId = () => {
    return this.signerIdentityId;
  };

  getInfo = () => {
    return get(`/signer_identities/${this.signerIdentityId}/info`).then((data) => {
      this.signerIdentityInfo = {
        fullName: data.full_name,
        secondaryIdRequired: data.secondary_id_required,
      };

      return this.signerIdentityInfo;
    });
  };

  secondaryIdRequired = () => {
    if (this.signerIdentityInfo) {
      return Promise.resolve(this.signerIdentityInfo.secondaryIdRequired);
    }
    return this.getInfo()
      .then((signerIdentityInfo) => signerIdentityInfo.secondaryIdRequired)
      .catch(() => false);
  };

  setBackPicture(picture) {
    this.backPicture = getBlobFromDataURI(picture);
    return this;
  }

  setFrontPicture(picture) {
    this.frontPicture = getBlobFromDataURI(picture);
    return this;
  }

  setDocumentClaimedType(selectedDocumentClaimedType) {
    this.documentClaimedType = selectedDocumentClaimedType;
    return this;
  }

  setPIN = (pin) => {
    this.pin = pin;
    return this;
  };

  updatePhotoIdentification = (logout = true) => {
    return Promise.all([this.frontUploadPromise, this.backUploadPromise]).then(() => {
      const documentClaimedType = this.documentClaimedType;
      this.setDocumentClaimedType(null);
      return put(
        `/signer_identities/${this.signerIdentityId}/photo_identifications/${this.photoIdentification.id}`,
        {
          pin: this.pin,
          document_claimed_type: documentClaimedType,
          logout,
        },
      );
    });
  };

  uploadBackPicture() {
    if (!this.backPicture || !this.photoIdentification.backUrl) {
      this.backUploadPromise = Promise.reject("Unable to upload picture: missing payload");
    } else {
      this.backUploadPromise = putFile(
        this.backPicture,
        this.photoIdentification.backUrl,
        "image/png",
      );
    }
    return this.backUploadPromise;
  }

  uploadFrontPicture() {
    if (!this.frontPicture || !this.photoIdentification.frontUrl) {
      this.frontUploadPromise = Promise.reject("Unable to upload picture: missing payload");
    } else {
      this.frontUploadPromise = putFile(
        this.frontPicture,
        this.photoIdentification.frontUrl,
        "image/png",
      );
    }
    return this.frontUploadPromise;
  }
}
