import "./index.scss";

import { FullLogo } from "common/core/logo";

function Header() {
  return (
    <div className="CaptureAppHeader">
      <FullLogo whiteLogo className="CaptureAppHeader--logo" />
    </div>
  );
}

export default Header;
