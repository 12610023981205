import "util/print_debug";
import "vendors/segment";
import "config/sentry";

import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { attachRootElement } from "common/app/root_element";
import ErrorBoundary from "common/error_boundary";
import Intl from "common/providers/intl";
import CaptureRouter from "capture_portal/router";

render(
  <Intl>
    <ErrorBoundary>
      <BrowserRouter>
        <CaptureRouter />
      </BrowserRouter>
    </ErrorBoundary>
  </Intl>,
  attachRootElement(),
);
