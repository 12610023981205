import "./index.scss";

import PropTypes from "prop-types";
import classnames from "classnames";

import Icon from "common/core/icon";
import Tooltip from "common/core/tooltip";
import { lerp } from "util/number";

const CX_NAME = "StepIndicator";

const BLUE = [30, 120, 210];
const GREEN = [42, 188, 157];

function mix(rgb1, rgb2, amount) {
  const red = Math.round(lerp(rgb1[0], rgb2[0], amount));
  const green = Math.round(lerp(rgb1[1], rgb2[1], amount));
  const blue = Math.round(lerp(rgb1[2], rgb2[2], amount));
  return [red, green, blue];
}

/**
 * Step within steps array
 * @typedef {object} Step
 * @property {string} name name of step (must be unique)
 * @property {SubStep[]} subSteps sub steps for step - last step cannot have sub steps (the bar needs to terminate with a dot)
 */

/**
 * Substep within step
 * @typedef {object} SubStep
 * @property {string} name name of sub step (must be unique within parent step)
 */

/**
 * A bar that shows which step in a list of steps a user is on. Supports sub steps.
 *
 * @prop {Step[]} steps steps that will be displayed on the step indicator
 * @prop {number} stepName current step name
 * @prop {bool} subStepName current sub step name
 * @prop {string} className class that will be applied to outermost div
 * @prop {bool} useNameAsLabel whether or not to use names as label instead of tooltips
 */
function StepIndicator(props) {
  const { steps, stepName, subStepName, className, useNameAsLabel } = props;

  const cx = classnames(CX_NAME, className);
  const barCx = classnames(`${CX_NAME}--bar`, {
    [`${CX_NAME}--bar__padding`]: useNameAsLabel,
  });
  let barStyleOverrides = {};

  const stepPercentage = 1 / (steps.length - 1);
  const individualStepWidth = 100.0 / steps.length;

  let innerPercent = 0;

  const currentStepIndex = steps.findIndex((step) => step.name === stepName);
  if (currentStepIndex !== -1) {
    innerPercent = currentStepIndex * stepPercentage;

    const { subSteps = [] } = steps[currentStepIndex];
    const currentSubStepIndex = subSteps.findIndex((subStep) => subStep.name === subStepName);
    if (currentSubStepIndex !== -1) {
      const subStepPercentage = stepPercentage / subSteps.length;
      innerPercent += currentSubStepIndex * subStepPercentage;
    }
  }

  const coverStyle = {
    width: `${(1 - innerPercent) * 100}%`,
  };

  const dots = steps.map((step, stepIndex) => {
    const isComplete = stepIndex < currentStepIndex;
    const isCurrent = stepIndex === currentStepIndex;
    const isFirst = stepIndex === 0;
    const isLast = stepIndex === steps.length - 1;

    const dotCx = classnames(`${CX_NAME}--dot`, {
      [`${CX_NAME}--dot__complete`]: isComplete,
      [`${CX_NAME}--dot__current`]: isCurrent,
      [`${CX_NAME}--dot__first`]: isFirst,
      [`${CX_NAME}--dot__last`]: isLast,
    });

    const [red, green, blue] = mix(BLUE, GREEN, stepIndex * stepPercentage);
    const dotStyle = {};
    if (isComplete) {
      dotStyle.background = `rgb(${red}, ${green}, ${blue})`;
      dotStyle.border = 0;
    } else if (isCurrent) {
      dotStyle.borderColor = `rgb(${red}, ${green}, ${blue})`;
    }

    const dot = (
      <div className={dotCx} style={dotStyle}>
        {isComplete && <Icon className={`${CX_NAME}--check`} name="tick-small" />}
      </div>
    );

    const dotLabelCx = classnames(`${CX_NAME}--dot__withLabel`, {
      [`${CX_NAME}--dot__withLabel__first`]: isFirst,
      [`${CX_NAME}--dot__withLabel__last`]: isLast,
    });

    if (useNameAsLabel) {
      barStyleOverrides = {
        width: `calc(100% - ${individualStepWidth}%)`,
        marginLeft: `calc(${individualStepWidth}% / 2)`,
      };
      return (
        <div key={step.name} className={dotLabelCx} style={{ width: `${individualStepWidth}%` }}>
          {dot}
          <div className={`${CX_NAME}--dot--text`}>{step.name}</div>
        </div>
      );
    }

    let placement = "bottom";
    if (isFirst) {
      placement = "bottomLeft";
    } else if (isLast) {
      placement = "bottomRight";
    }

    return (
      <Tooltip key={step.name} target={dot} placement={placement}>
        {step.name}
      </Tooltip>
    );
  });

  return (
    <div className={cx}>
      <div className={barCx} style={barStyleOverrides}>
        <div className={`${CX_NAME}--cover`} style={coverStyle} />
      </div>
      <div className={`${CX_NAME}--dots`}>{dots}</div>
    </div>
  );
}

StepIndicator.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      // step names must be unique
      name: PropTypes.string.isRequired,
      // the last step cannot have sub steps (the bar needs to terminate with a dot)
      subSteps: PropTypes.arrayOf(
        PropTypes.shape({
          // sub step names must be unique (within their parent step)
          name: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  stepName: PropTypes.string,
  subStepName: PropTypes.string,
  className: PropTypes.string,
  useNameAsLabel: PropTypes.bool,
};

StepIndicator.defaultProps = {
  stepName: null,
  subStepName: null,
  className: null,
};

export default StepIndicator;
