import Env from "config/environment";

const url = `${Env.apiHost}/capture`;

function prepareUrl(path) {
  return [url, path.replace(/^\/*/gm, "")].join("/");
}

function safeJsonCallback(response) {
  if (!response.ok) {
    throw response.statusText;
  } else if (response.status === 204) {
    return null;
  }
  return response.json();
}

export function get(path, params = {}) {
  const url = new URL(prepareUrl(path));

  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

  // .href here is not necessary since url.toString() resolves to .href but
  // it's easier to test the value passed to fetch using .href instead of the url object.
  return fetch(url.href).then(safeJsonCallback);
}

export function post(path, params = {}) {
  return fetch(prepareUrl(path), {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
  }).then(safeJsonCallback);
}

export function put(path, params = {}) {
  return fetch(prepareUrl(path), {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
  }).then(safeJsonCallback);
}

export function putFile(file, url, fileType = "") {
  return fetch(url, { method: "PUT", headers: { "Content-Type": fileType }, body: file }).then(
    (response) => {
      if (!response.ok) {
        throw response.statusText;
      }
      return null;
    },
  );
}
