import "./index.scss";

import { Component } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import successfulBackId from "assets/images/identification_check/license-back-good.png";
import { CAPTURE_ID_SIDE } from "constants/id_validation";

import Button from "../common/button";
import Camera from "../camera";
import ConfirmPhoto from "../confirm_photo";

class BackCapture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCapturing: false,
      screenshot: null,
    };
  }

  onRetake = () => {
    const { signerIdentityService: service, captureAnalytics } = this.props;

    this.setState({ isCapturing: true, screenshot: null }, () => {
      captureAnalytics.trackSignerRetakeId({
        signerIdentityId: service.getId(),
        idSide: CAPTURE_ID_SIDE.BACK,
      });
      service.clearBackPicture();
    });
  };

  onStartCapture = () => {
    const { signerIdentityService: service, captureAnalytics } = this.props;

    this.setState({ isCapturing: true, screenshot: null }, () => {
      captureAnalytics.trackSignerStartCapturingId({
        idSide: CAPTURE_ID_SIDE.BACK,
        signerIdentityId: service.getId(),
      });
      service.clearBackPicture();
    });
  };

  onFinishCapture = (screenshot) => {
    const { signerIdentityService: service, captureAnalytics } = this.props;

    this.setState({ isCapturing: false, screenshot }, () => {
      captureAnalytics.trackSignerCapturedId({
        signerIdentityId: service.getId(),
        idSide: CAPTURE_ID_SIDE.BACK,
      });
      service.setBackPicture(screenshot);
    });
  };

  onConfirmBackPhoto = () => {
    const { navigate, signerIdentityService: service, captureAnalytics } = this.props;

    return service.secondaryIdRequired().then((secondaryIdRequired) => {
      if (secondaryIdRequired) {
        return service
          .uploadBackPicture()
          .then(() => service.updatePhotoIdentification(false))
          .then(() => {
            service.clearFrontPicture();
            service.clearBackPicture();
          })
          .then(() => navigate(`/secondary/${service.getId()}`));
      }

      captureAnalytics.trackSignerUpdateMeetingIds({
        idSide: CAPTURE_ID_SIDE.BACK,
        signerIdentityId: service.getId(),
      });

      return service
        .uploadBackPicture()
        .then(service.updatePhotoIdentification)
        .then(() => navigate("/submit"));
    });
  };

  render() {
    let content;
    if (this.state.isCapturing) {
      const { signerIdentityService: service, captureAnalytics } = this.props;
      content = (
        <Camera
          signerIdentityId={service.getId()}
          onCapture={this.onFinishCapture}
          trackWebcamError={captureAnalytics.trackWebcamError}
          isBack
        />
      );
    } else if (this.state.screenshot) {
      content = (
        <ConfirmPhoto
          screenshot={this.state.screenshot}
          onConfirm={this.onConfirmBackPhoto}
          onRetake={this.onRetake}
          isBack
        />
      );
    } else {
      content = (
        <div className="CaptureApp--backCapture">
          <div className="CaptureApp--backCapture--header">
            Take a photo of the back of your ID.
          </div>
          <img
            alt="correct ID example"
            src={successfulBackId}
            className="CaptureApp--backCapture--example"
          />
          <div className="CaptureApp--backCapture--buttons">
            <Button
              action
              large
              fullwidth
              onClick={this.onStartCapture}
              automationId="start-capture"
            >
              <FormattedMessage
                id="1be67f61-fcc4-42a5-9c32-d4b1fe8fdb98"
                defaultMessage="Take photo"
              />
            </Button>
          </div>
        </div>
      );
    }

    return content;
  }
}

BackCapture.propTypes = {
  signerIdentityService: PropTypes.shape({
    setBackPicture: PropTypes.func.isRequired,
    clearBackPicture: PropTypes.func.isRequired,
    uploadBackPicture: PropTypes.func.isRequired,
    updatePhotoIdentification: PropTypes.func.isRequired,
  }).isRequired,

  // CaptureAnalyticsContainer
  captureAnalytics: PropTypes.shape({
    trackSignerRetakeId: PropTypes.func.isRequired,
    trackSignerStartCapturingId: PropTypes.func.isRequired,
    trackSignerCapturedId: PropTypes.func.isRequired,
    trackSignerUpdateMeetingIds: PropTypes.func.isRequired,
    trackWebcamError: PropTypes.func.isRequired,
  }).isRequired,
};

export default (props) => {
  const navigate = useNavigate();
  return <BackCapture {...props} navigate={navigate} />;
};
