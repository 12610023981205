import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import successfulId from "assets/images/identification_check/successful-id.png";
import { CAPTURE_ID_SIDE } from "constants/id_validation";

import Button from "../common/button";
import Camera from "../camera";
import ConfirmPhoto from "../confirm_photo";

class FrontCapture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCapturing: false,
      screenshot: null,
    };
  }

  onRetake = () => {
    const { signerIdentityService: service, captureAnalytics } = this.props;

    this.setState({ isCapturing: true, screenshot: null }, () => {
      captureAnalytics.trackSignerRetakeId({
        signerIdentityId: service.getId(),
        idSide: CAPTURE_ID_SIDE.FRONT,
      });
      service.clearFrontPicture();
    });
  };

  onStartCapture = () => {
    const { signerIdentityService: service, captureAnalytics } = this.props;

    this.setState({ isCapturing: true, screenshot: null }, () => {
      captureAnalytics.trackSignerStartCapturingId({
        signerIdentityId: service.getId(),
        idSide: CAPTURE_ID_SIDE.FRONT,
      });
      service.clearFrontPicture();
    });
  };

  onFinishCapture = (screenshot) => {
    const { signerIdentityService: service, captureAnalytics } = this.props;

    this.setState({ isCapturing: false, screenshot }, () => {
      captureAnalytics.trackSignerCapturedId({
        signerIdentityId: service.getId(),
        idSide: CAPTURE_ID_SIDE.FRONT,
      });
      service.setFrontPicture(screenshot);
    });
  };

  onConfirmFrontPhoto = () => {
    const { navigate, signerIdentityService: service, captureAnalytics } = this.props;

    captureAnalytics.trackSignerConfirmId({
      idSide: CAPTURE_ID_SIDE.FRONT,
      signerIdentityId: service.getId(),
    });
    return service.uploadFrontPicture().then(() => navigate(`/back/${service.getId()}`));
  };

  render() {
    let content;
    if (this.state.isCapturing) {
      const { signerIdentityService: service, captureAnalytics } = this.props;
      content = (
        <Camera
          signerIdentityId={service.getId()}
          onCapture={this.onFinishCapture}
          trackWebcamError={captureAnalytics.trackWebcamError}
        />
      );
    } else if (this.state.screenshot) {
      content = (
        <ConfirmPhoto
          screenshot={this.state.screenshot}
          onConfirm={this.onConfirmFrontPhoto}
          onRetake={this.onRetake}
        />
      );
    } else {
      content = (
        <div className="CaptureApp--frontCapture">
          <div className="CaptureApp--frontCapture--header">
            Take a photo of the front of your ID.
          </div>
          <img
            alt="correct ID example"
            src={successfulId}
            className="CaptureApp--frontCapture--example"
          />
          <div className="CaptureApp--frontCapture--buttons">
            <Button
              className="CaptureApp--frontCapture--buttons--button"
              action
              fullwidth
              large
              onClick={this.onStartCapture}
              automationId="start-capture"
            >
              <FormattedMessage
                id="ae31410c-9291-48ba-b063-78772b283f5f"
                defaultMessage="Take photo"
              />
            </Button>
          </div>
        </div>
      );
    }

    return content;
  }
}

FrontCapture.propTypes = {
  signerIdentityService: PropTypes.shape({
    setFrontPicture: PropTypes.func.isRequired,
    clearFrontPicture: PropTypes.func.isRequired,
    uploadFrontPicture: PropTypes.func.isRequired,
  }).isRequired,

  // CaptureAnalyticsContainer
  captureAnalytics: PropTypes.shape({
    trackSignerRetakeId: PropTypes.func.isRequired,
    trackSignerStartCapturingId: PropTypes.func.isRequired,
    trackSignerCapturedId: PropTypes.func.isRequired,
    trackSignerConfirmId: PropTypes.func.isRequired,
    trackWebcamError: PropTypes.func.isRequired,
  }).isRequired,
};

export default (props) => {
  const navigate = useNavigate();
  return <FrontCapture {...props} navigate={navigate} />;
};
