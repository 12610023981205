import "./index.scss";

import type { ReactNode, ComponentProps } from "react";
import { defineMessages, useIntl } from "react-intl";

import RadioButton from "common/form/inputs/radio";
import Tooltip from "common/core/tooltip";
import Icon from "common/core/icon";

type Props<V extends string> = {
  label: ComponentProps<typeof RadioButton>["labelText"];
  value: NonNullable<V>;
  groupValue?: string;
  helpContent?: ReactNode;
  onChange: (newValue: NonNullable<V>) => void;
};

const MESSAGES = defineMessages({
  tooltipTriggerLabel: {
    id: "020022c3-4b5a-4b4a-8882-8501130b42cc",
    defaultMessage: "More details about this form of identification",
  },
});

function IdentificationCheckRadioOption<V extends string = string>(props: Props<V>) {
  const { label, value, groupValue, helpContent, onChange } = props;
  const intl = useIntl();

  return (
    <div className="IdentificationCheckRadioOption--container">
      <RadioButton<V>
        labelText={label}
        radioValue={value}
        onChange={onChange}
        groupValue={groupValue as V}
        size="small"
      />
      {helpContent && (
        <Tooltip
          target={<Icon className="IdentificationCheckRadioOption--icon" name="question" />}
          placement="rightTop"
          triggerButtonLabel={intl.formatMessage(MESSAGES.tooltipTriggerLabel)}
        >
          {helpContent}
        </Tooltip>
      )}
    </div>
  );
}

export default IdentificationCheckRadioOption;
