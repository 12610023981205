import { PureComponent } from "react";
import PropTypes from "prop-types";

import { segmentTrack } from "util/segment";
import { EVENT } from "constants/analytics";

/**
 * @description
 * Capture App Analytics are defined here and returned
 * @property {function} trackSignerEnterCaptureApp: Tracks whenever someone visits the capture portal
 * @property {function} trackAuthorizationAttempt: Tracks when user tries to authenticate with a pin
 * @property {function} trackAuthorizationSuccess: Tracks when user successfully authenticates
 * @property {function} trackSignerStartCapturingId: Tracks when user starts to capture their id
 * @property {function} trackSignerCapturedId: Tracks when user captured id
 * @property {function} trackSignerRetakeId: Tracks if a user decides to retake their id
 * @property {function} trackSignerConfirmId: Tracks when user confirms use of their id
 * @property {function} trackSignerSubmitIdSuccessful: Tracks when user successfully submits their id
 * @property {function} trackSignerSkipSecondaryId: Tracks when user skips secondary id
 * @property {function} trackWebcamError: Tracks when the user webcam is not accessible
 * @returns  {Object} with all the objects
 */
const CaptureAnalytics = () => ({
  trackSignerEnterCaptureApp({ signerIdentityId }) {
    segmentTrack(EVENT.SIGNER_ENTERED_CAPTURE_APP, {
      signer_identity_id: signerIdentityId,
    });
  },
  trackAuthorizationAttempt({ signerIdentityId, pin }) {
    segmentTrack(EVENT.SIGNER_CAPTURE_AUTHORIZATION_ATTEMPTED, {
      signer_identity_id: signerIdentityId,
      pin,
    });
  },
  trackAuthorizationSuccess({ signerIdentityId }) {
    segmentTrack(EVENT.SIGNER_CAPTURE_AUTHORIZATION_SUCCESS, {
      signer_identity_id: signerIdentityId,
    });
  },
  trackSignerStartCapturingId({ signerIdentityId, idSide }) {
    segmentTrack(EVENT.SIGNER_START_CAPTURING_ID, {
      signer_identity_id: signerIdentityId,
      id_side: idSide,
    });
  },
  trackSignerCapturedId({ signerIdentityId, idSide }) {
    segmentTrack(EVENT.SIGNER_CAPTURED_ID, {
      signer_identity_id: signerIdentityId,
      id_side: idSide,
    });
  },
  trackSignerRetakeId({ signerIdentityId, idSide }) {
    segmentTrack(EVENT.SIGNER_RETAKE_ID, {
      signer_identity_id: signerIdentityId,
      id_side: idSide,
    });
  },
  trackSignerConfirmId({ signerIdentityId, idSide }) {
    segmentTrack(EVENT.SIGNER_CONFIRMED_ID, {
      signer_identity_id: signerIdentityId,
      id_side: idSide,
    });
  },
  trackSignerUpdateMeetingIds({ signerIdentityId }) {
    segmentTrack(EVENT.SIGNER_UPDATE_MEETING_IDS, {
      signer_identity_id: signerIdentityId,
    });
  },
  trackSignerSubmitIdSuccessful({ signerIdentityId }) {
    segmentTrack(EVENT.SIGNER_SUBMIT_ID_SUCCESSFUL, {
      signer_identity_id: signerIdentityId,
    });
  },
  trackSignerSkipSecondaryId({ signerIdentityId }) {
    segmentTrack(EVENT.SIGNER_SKIP_SECONDARY_ID, {
      signer_identity_id: signerIdentityId,
    });
  },
  trackWebcamError({ signerIdentityId, error }) {
    segmentTrack(EVENT.CAPTURE_PORTAL_CAMERA_ERROR, {
      signer_identity_id: signerIdentityId,
      webcam_error: error.name,
    });
  },
});

/**
 * Container that wraps and passes the capture app an analytics object with functions that
 * it can call to log events.
 */
class CaptureAnalyticsContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.analytics = CaptureAnalytics();
  }

  render() {
    return this.props.children(this.analytics);
  }
}

CaptureAnalyticsContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default CaptureAnalyticsContainer;
