import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Webcam from "util/react_user_media";
import captureButton from "assets/images/capture_portal/capture-button.svg";

// https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#Exceptions
const NOT_FOUND_ERROR = "NotFoundError";
const NOT_ALLOWED_ERROR = "NotAllowedError";
const UNKNOWN_ERROR = "UNKNOWN_ERROR"; // just a catch all error

class Camera extends Component {
  state = {
    webcamFailure: false,
  };

  handleWebcamSuccess = () => {
    this.setState({ webcamFailure: false });
  };

  handleWebcamFailure = (error) => {
    const { trackWebcamError, signerIdentityId } = this.props;
    trackWebcamError({ error, signerIdentityId });
    if (error.name === NOT_FOUND_ERROR) {
      this.setState({ webcamFailure: NOT_FOUND_ERROR });
    } else if (error.name === NOT_ALLOWED_ERROR) {
      this.setState({ webcamFailure: NOT_ALLOWED_ERROR });
    } else {
      this.setState({ webcamFailure: UNKNOWN_ERROR });
    }
  };

  capture = () => {
    const screenshot = this.webcamRef.captureScreenshot();
    this.props.onCapture(screenshot);
  };

  renderSpecificWebcamError = () => {
    const { webcamFailure } = this.state;
    let errorText;
    switch (webcamFailure) {
      case NOT_FOUND_ERROR:
        errorText = "No camera was found.";
        break;
      case NOT_ALLOWED_ERROR:
        errorText = "We are not permitted to access your camera.";
        break;
      default:
        errorText =
          "Your browser does not support accessing webcams. Please upgrade or switch to a different one";
        break;
    }
    return (
      <div className="CaptureApp--captureFailure--error">
        <div className="CaptureApp--captureFailure--error--header">
          There was an issue accessing your webcam.
        </div>
        <ul className="CaptureApp--captureFailure--error--content">
          <li>{errorText}</li>
          <li>Please check your browser and mobile device preferences and try again.</li>
        </ul>
      </div>
    );
  };

  render() {
    const { isBack } = this.props;
    const overlayCX = classnames("CaptureApp--capture--overlay", {
      front: !isBack,
      back: isBack,
    });

    if (this.state.webcamFailure) {
      return <div className="CaptureApp--captureFailure">{this.renderSpecificWebcamError()}</div>;
    }

    return (
      <div className="CaptureApp--capture">
        <div className={overlayCX} />
        <div className="CaptureApp--capture--camera">
          <Webcam
            ref={(ref) => {
              this.webcamRef = ref;
            }}
            video={{
              facingMode: "environment",
              // NOTE: Safari only supports 1280x720
              width: {
                ideal: 1920,
              },
              height: {
                ideal: 1080,
              },
            }}
            audio={false}
            width="100%"
            height="100%"
            captureFormat="image/png"
            playsInline
            onFailure={this.handleWebcamFailure}
            onSuccess={this.handleWebcamSuccess}
          />
        </div>
        <div className="CaptureApp--capture--actions">
          <img
            className="CaptureApp--capture--actions--button"
            src={captureButton}
            alt="capture button"
            onClick={this.capture}
            data-automation-id="capture-screenshot"
          />
        </div>
      </div>
    );
  }
}

Camera.propTypes = {
  signerIdentityId: PropTypes.string.isRequired,
  trackWebcamError: PropTypes.func.isRequired,
  onCapture: PropTypes.func.isRequired,
  isBack: PropTypes.bool,
};

export default Camera;
