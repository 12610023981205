import "./index.scss";

import { Component } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import IdentificationCheckRadioOption from "signer_portal/identification_check/radio_option";
import SelectIdType from "assets/images/capture_portal/select-id-type.svg";
import { PASSPORT, DRIVERS_LICENSE, ID_CARD } from "constants/id_validation";

import Button from "../common/button";

class SelectType extends Component {
  state = {
    documentClaimedType: null,
  };

  handleDocumentClaimedType = (documentClaimedType) => {
    this.setState({ documentClaimedType });
  };

  onConfirm = () => {
    const { navigate, signerIdentityService: service } = this.props;
    service.setDocumentClaimedType(this.state.documentClaimedType);
    navigate(`/front/${service.getId()}`);
  };

  render() {
    const { documentClaimedType } = this.state;
    return (
      <div className="SelectType">
        <img className="SelectType--image" alt="select id type" src={SelectIdType} />
        <div className="SelectType--text SelectType--text__secondary">
          For security we must capture your photo ID for verification
        </div>
        <div className="SelectType--text SelectType--text__primary">
          Please select a valid unexpired form of ID:
        </div>
        <div className="SelectType--documentList">
          <IdentificationCheckRadioOption
            label={
              <FormattedMessage
                id="fcbe06ef-fff2-4b80-b51d-4a73882d097a"
                defaultMessage="US Driver's License"
              />
            }
            value={DRIVERS_LICENSE}
            groupValue={documentClaimedType}
            onChange={this.handleDocumentClaimedType}
          />
          <IdentificationCheckRadioOption
            label={
              <FormattedMessage
                id="4f30a967-64b4-4185-92fc-3cfcfede1dda"
                defaultMessage="Other US Government-Issued ID"
              />
            }
            value={ID_CARD}
            groupValue={documentClaimedType}
            onChange={this.handleDocumentClaimedType}
            helpContent={
              <ul>
                <FormattedMessage
                  id="8342da0e-e4d9-40f9-ac8f-7a020d17ab74"
                  defaultMessage="Non-driver’s identification card"
                  tagName="li"
                />
                <FormattedMessage
                  id="72cde381-ac2d-405c-b8f8-b8f78d0cf30f"
                  defaultMessage="US Armed Forces card"
                  tagName="li"
                />
                <FormattedMessage
                  id="4773743e-275c-4717-afaa-a8dec229f2d7"
                  defaultMessage="Uniformed Service ID card"
                  tagName="li"
                />
                <FormattedMessage
                  id="15482a0a-158a-4e19-ad04-86498104d915"
                  defaultMessage="US permanent resident identification card"
                  tagName="li"
                />
                <FormattedMessage
                  id="6ec27a81-9842-4d14-97f2-4339651f24d0"
                  defaultMessage="US certificate of citizenship or naturalization"
                  tagName="li"
                />
                <FormattedMessage
                  id="9e16f2d9-1adb-4864-a3dd-c722db31eaf8"
                  defaultMessage="Tribal identification card"
                  tagName="li"
                />
              </ul>
            }
          />
          <IdentificationCheckRadioOption
            label={
              <FormattedMessage
                id="73501d74-b14b-4d43-8a0b-211c71bb64d8"
                defaultMessage="Passport"
              />
            }
            value={PASSPORT}
            groupValue={documentClaimedType}
            onChange={this.handleDocumentClaimedType}
          />
        </div>
        <Button
          action
          small
          className="SelectType--button"
          onClick={this.onConfirm}
          disabled={!documentClaimedType}
          automationId="select-id-type-confirm"
        >
          Confirm
        </Button>
      </div>
    );
  }
}

export default (props) => {
  const navigate = useNavigate();
  return <SelectType {...props} navigate={navigate} />;
};
