import "./index.scss";

import { Component } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { captureException } from "util/exception";
import { CAPTURE_ID_SIDE, CAPTURE_ID_TYPE } from "constants/id_validation";
import secondaryIdExample from "assets/images/capture_portal/secondary-id-example.svg";

import Camera from "../camera";
import ConfirmPhoto from "../confirm_photo";
import Button from "../common/button";

class SecondaryCapture extends Component {
  state = {
    isCapturing: false,
    isSubmitting: false,
    isUploading: false,
    screenshot: null,
  };

  onConfirmSecondaryPhoto = () => {
    const { navigate, signerIdentityService: service, captureAnalytics } = this.props;

    captureAnalytics.trackSignerConfirmId({
      idSide: CAPTURE_ID_SIDE.FRONT,
      signerIdentityId: service.getId(),
    });

    return service
      .uploadFrontPicture()
      .then(service.updatePhotoIdentification)
      .then(() => navigate("/submit"));
  };

  onFinishCapture = (screenshot) => {
    const { signerIdentityService: service, captureAnalytics } = this.props;

    captureAnalytics.trackSignerCapturedId({
      signerIdentityId: service.getId(),
      idSide: CAPTURE_ID_SIDE.FRONT,
    });

    this.setState({ isCapturing: false, screenshot }, () => {
      service.setFrontPicture(screenshot);
    });
  };

  onRetake = () => {
    const { signerIdentityService: service, captureAnalytics } = this.props;

    captureAnalytics.trackSignerRetakeId({
      signerIdentityId: service.getId(),
      idSide: CAPTURE_ID_SIDE.FRONT,
    });

    this.setState({ isCapturing: true, screenshot: null }, () => {
      service.clearFrontPicture();
    });
  };

  onSkipCapture = () => {
    const { navigate, signerIdentityService: service, captureAnalytics } = this.props;

    captureAnalytics.trackSignerSkipSecondaryId({
      signerIdentityId: service.getId(),
    });

    navigate("/submit");
  };

  onStartCapture = () => {
    const { signerIdentityService: service, captureAnalytics } = this.props;

    captureAnalytics.trackSignerStartCapturingId({
      idSide: CAPTURE_ID_SIDE.FRONT,
      signerIdentityId: service.getId(),
    });

    service
      .createPhotoIdentification(CAPTURE_ID_TYPE.SECONDARY)
      .then(() => {
        this.setState({ isCapturing: true, screenshot: null });
      })
      .catch(captureException);
  };

  render() {
    const { isCapturing, isSubmitting, isUploading, screenshot } = this.state;
    const { signerIdentityService: service, captureAnalytics } = this.props;

    let content;

    if (isCapturing) {
      content = (
        <Camera
          signerIdentityId={service.getId()}
          onCapture={this.onFinishCapture}
          trackWebcamError={captureAnalytics.trackWebcamError}
        />
      );
    } else if (screenshot) {
      content = (
        <ConfirmPhoto
          screenshot={screenshot}
          onConfirm={this.onConfirmSecondaryPhoto}
          onRetake={this.onRetake}
        />
      );
    } else {
      content = (
        <div className="CaptureApp--SecondaryCapture">
          <div className="CaptureApp--SecondaryCapture--imageWrapper">
            <img
              className="CaptureApp--SecondaryCapture--imageWrapper--image"
              alt="secondary ID"
              src={secondaryIdExample}
            />
            <div className="CaptureApp--SecondaryCapture--imageWrapper--imageText">
              <FormattedMessage
                id="3a530a2f-5bbe-45d9-b2f4-c695f9ac78ba"
                defaultMessage="Some documents require a secondary ID. Please check your document to confirm any additional requirements."
              />
            </div>
          </div>
          <div className="CaptureApp--SecondaryCapture--text">
            We may need a photograph of the page or card that includes your name, address, and one
            of the following:
            <ul className="CaptureApp--SecondaryCapture--list">
              <li className="CaptureApp--SecondaryCapture--list--item">
                Passport, certificate of naturalization or alien registration card.
              </li>
              <li className="CaptureApp--SecondaryCapture--list--item">
                Deed of Trust, current lease or mortgage.
              </li>
              <li className="CaptureApp--SecondaryCapture--list--item">
                Vehicle registration card, or a home or vehicle insurance policy.
              </li>
              <li className="CaptureApp--SecondaryCapture--list--item">
                Identification cards such as: state non-driver's, government or university ID.
              </li>
            </ul>
          </div>
          <div className="CaptureApp--SecondaryCapture--buttons">
            <Button
              action
              large
              fullwidth
              disabled={isSubmitting || isUploading}
              isLoading={isUploading}
              onClick={this.onStartCapture}
              automationId="start-capture"
            >
              <FormattedMessage
                id="a7447e3b-3cce-40aa-bf64-21e9f31bbbcb"
                defaultMessage="Take photo"
              />
            </Button>
            {!service.secondaryIdRequired() && (
              <Button
                white
                large
                fullwidth
                disabled={isSubmitting || isUploading}
                isLoading={isSubmitting}
                onClick={this.onSkipCapture}
                automationId="skip-capture"
              >
                Skip
              </Button>
            )}
          </div>
        </div>
      );
    }

    return content;
  }
}

SecondaryCapture.propTypes = {
  signerIdentityService: PropTypes.shape({
    setFrontPicture: PropTypes.func.isRequired,
    clearBackPicture: PropTypes.func.isRequired,
    clearFrontPicture: PropTypes.func.isRequired,
    uploadFrontPicture: PropTypes.func.isRequired,
    createPhotoIdentification: PropTypes.func.isRequired,
    updatePhotoIdentification: PropTypes.func.isRequired,
  }).isRequired,

  // CaptureAnalyticsContainer
  captureAnalytics: PropTypes.shape({
    trackSignerRetakeId: PropTypes.func.isRequired,
    trackSignerStartCapturingId: PropTypes.func.isRequired,
    trackSignerCapturedId: PropTypes.func.isRequired,
    trackSignerConfirmId: PropTypes.func.isRequired,
    trackSignerSkipSecondaryId: PropTypes.func.isRequired,
    trackWebcamError: PropTypes.func.isRequired,
  }).isRequired,
};

export default (props) => {
  const navigate = useNavigate();
  return <SecondaryCapture {...props} navigate={navigate} />;
};
